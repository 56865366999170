<template>
  <Modal :width="720" @on-visible-change="valueChanged" :title="title" :value="value" @input="emitInput">
    <Table
      @on-current-change="getChose"
      :highlight-row="true"
      size="small"
      :columns="table_columns"
      :data="table_data"
      :loading="table_loading"
    ></Table>
    <div style="text-align:right;margin-top:10px">
      <Page
        size="small"
        @on-change="pageChange"
        :current="page_data.pageNo"
        :total="page_data.total"
        :page-size="page_data.pageSize"
      ></Page>
    </div>
    <div slot="footer" style="text-align:center">
      <Button @click="submit" type="primary">确定</Button>
    </div>
  </Modal>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "选择授权设备"
    },
    default: Object,
    exclude: String
  },
  data() {
    return {
      // 表格列
      table_columns: [
        {
          title: 'logo',
          width: 80,
          render: (h, { row }) => {
            return <img src={row.logo ? row.servicePath + row.logo : ''} />
          }
        },
        {
          title: "设备名称",
          align: "center",
          key: "deviceName"
        },
        {
          title: "设备型号",
          align: "center",
          key: "deviceModel"
        },
      ],
      //   表格加载
      table_loading: false,
      // 表格数据
      table_data: [],

      // 分页数据
      page_data: {
        pageNo: 1,
        total: 0,
        pageSize: 6
      },

      //   当前选中行
      current_chose: null
    };
  },
  methods: {
    // 页面改变
    pageChange(pageNo) {
      this.page_data.pageNo = pageNo;
      this.getList();
    },
    //   加载表格数据
    getList() {
      this.table_loading = true;
      this.$post(this.$api.DEVICE_LL.LIST, {
        pageNo: this.page_data.pageNo,
        pageSize: this.page_data.pageSize
      })
        .then(res => {
          this.page_data.total = +res.total;
          if (this.exclude) {
            let index = res.list.findIndex(item => item.guid === this.exclude);
            if (index >= 0) {
              res.list.splice(index, 1);
            }
          }
          if (this.default) {
            res.list.forEach(element => {
              if (
                this.default.guid &&
                this.default.guid + "" === element.guid + ""
              ) {
                this.current_chose = {...element};
                element._highlight = true;
              }
            });
          }
          this.table_data = res.list;
          this.table_loading = false;
        })
        .catch(() => {
          this.table_loading = false;
        });
    },
    //  冒泡input使事件
    emitInput(val) {
      this.$emit("input", val);
    },
    // value改变回调
    valueChanged(visible) {
      if (visible) {
        // if (this.default) {
        //   this.current_chose = { ...this.default };
        // }
        this.getList();
      } else {
        this.page_data.pageNo = 1;
        this.page_data.total = 0;
        this.table_data = [];
        this.current_chose = null;
      }
    },
    // 选中某行
    getChose(current) {
      this.current_chose = { ...current };
    },
    // 提交
    submit() {
      this.$emit("submit", this.current_chose);
    }
  }
};
</script>
<style lang="less" scoped>
</style>