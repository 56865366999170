<template>
  <div>
    <Modal
      :value="value"
      :title="title"
      @input="(val) => $emit('input', val)"
      :width="900"
      @on-visible-change="modalShow"
    >
      <Form ref="form" :model="form" :rules="rules" :label-width="110">
        <Row>
          <Col span="11">
            <FormItem prop="name" label="自定义名称">
              <Input placeholder="请输入自定义名称" v-model="form.name" />
            </FormItem>
          </Col>
          <Col span="11" offset="1">
            <FormItem prop="deviceGuid" label="授权设备">
              <Input
                @on-click="choseDevice"
                @on-focus="choseDevice"
                readonly
                placeholder="请选择授权设备"
                icon="ios-search"
                v-model="form.deviceName"
              />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="11">
            <FormItem prop="deviceType" label="网关设备类型">
              <Select style="width: 100%" v-model="form.deviceType">
                <Option :value="0">无</Option>
                <Option :value="1">罗拉无线</Option>
                <Option :value="2">聚英有线</Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="11" offset="1">
            <FormItem prop="farmId" label="基地">
              <Select v-model="form.farmId" style="width: 100%">
                <Option
                  v-for="(item, index) in farmList"
                  :value="item.id"
                  :key="index"
                  >{{ item.name }}</Option
                >
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="11">
            <FormItem prop="authorizedTime" label="授权时间">
              <DatePicker
                :transfer="true"
                style="width: 100%"
                type="datetime"
                format="yyyy-MM-dd HH:mm:ss"
                @input="getAuthorizedTime"
                :value="form.authorizedTime"
              ></DatePicker>
            </FormItem>
          </Col>
          <Col span="11" offset="1">
            <FormItem prop="warrantyPeriod" label="质保期">
              <Input disabled style="width: 100%" v-model="form.warrantyPeriod">
                <span slot="append">月</span>
              </Input>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="11">
            <FormItem prop="qualityDeadline" label="质保到期时间">
              <DatePicker
                :transfer="true"
                style="width: 100%"
                type="datetime"
                format="yyyy-MM-dd HH:mm:ss"
                v-model="form.qualityDeadline"
              />
            </FormItem>
          </Col>
          <Col span="11" offset="1">
            <FormItem prop="vestingUnit" label="归属单位">
              <Input placeholder="请填写" v-model="form.vestingUnit" />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="11">
            <FormItem prop="address" label="设备位置">
              <Input
                placeholder="点击右侧按钮选择位置"
                disabled
                v-model="form.address"
              >
                <Icon type="ios-pin" slot="append" @click="mapModelShow" />
              </Input>
            </FormItem>
          </Col>
          <Col span="11" offset="1">
            <FormItem class="remark-item" prop="remarks" label="备注">
              <Input type="textarea" v-model="form.remarks" />
            </FormItem>
          </Col>
        </Row>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" :loading="loading" @click="submit">提交</Button>
        <Button @click="() => $emit('input', false)">取消</Button>
      </p>
    </Modal>
    <chose-device-modal
      @submit="getDevice"
      :default="chose_device_modal_data.default"
      v-model="chose_device_modal_data.show"
    ></chose-device-modal>
    <Modal v-model="mapModel" fullscreen>
      <search-map
        v-if="mapModel"
        :currentData="currentData"
        :backgroundPosition="backgroundPosition"
        @back-location="dealMapData"
      ></search-map>
    </Modal>
  </div>
</template>

<script>
import choseDeviceModal from "./modal_chose_device";
import CU from "@/common/util";
import moment from "moment";
import searchMap from "@/components/searchMap";
export default {
  name: "",
  components: {
    choseDeviceModal,
    searchMap,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: String,
    editData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        name: "",
        deviceGuid: "",
        deviceName: "",
        authorizedTime: new Date(),
        isOnline: "",
        warrantyPeriod: null,
        qualityDeadline: new Date(),
        buildUnit: "",
        vestingUnit: "",
        remarks: "",
        deviceType: "",
        vestingNo: localStorage.companyNo,
        status: "1",
        address: "",
        farmId: "",
      },
      rules: {
        name: [{ required: true, message: "请输入自定义名称" }],
        deviceGuid: [{ required: true, message: "请选择要授权的设备" }],
        deviceType: [{ required: true, message: "请选择网关设备类型" }],
        authorizedTime: [{ required: true, message: "请选择授权时间" }],
        warrantyPeriod: [{ required: true, message: "请填写质保期" }],
        qualityDeadline: [{ required: true, message: "请选择质保到期时间" }],
        vestingUnit: [{ required: true, message: "请选择归属单位" }],
        address: [{ required: true, message: "请选择设备位置" }],
        farmId: [{ required: true, type: "number", meesage: "请选择基地" }],
      },
      // 选择设备弹窗数据
      chose_device_modal_data: {
        show: false,
        default: null,
      },
      farmList: [],
      // 地图弹窗显示切换
      mapModel: false,
      currentData: null,
      loading: false,
      // 地图背景地址
      backgroundPosition: null,
    };
  },
  methods: {
    modalShow(visible) {
      if (visible) {
        this.getfarm();
        if (this.editData) {
          for (let key in this.editData) {
            this.form[key] = this.editData[key];
          }
        }
      } else {
        this.form = {
          name: "",
          deviceGuid: "",
          deviceName: "",
          authorizedTime: new Date(),
          isOnline: "",
          warrantyPeriod: null,
          qualityDeadline: new Date(),
          buildUnit: "",
          vestingUnit: "",
          remarks: "",
          deviceType: "",
          vestingNo: localStorage.companyNo,
          status: "1",
          address: "",
          farmId: "",
        };
        this.$refs.form.resetFields();
        this.farmList = [];
        this.currentData = null;
      }
    },

    //   选择授权设备
    choseDevice() {
      this.chose_device_modal_data.show = true;
    },
    // 得到选定的设备
    getDevice(device) {
      this.chose_device_modal_data.default = { ...device };
      this.getWarrantyPeriod(device.warrantyPeriod);
      this.form.deviceGuid = device.guid;
      this.form.deviceName = device.deviceName;
      this.chose_device_modal_data.show = false;
    },
    // 得到质保期
    getWarrantyPeriod(val) {
      if (CU.validateNumber(val, 0, undefined, 0)) {
        this.form.warrantyPeriod = val;
        if (
          this.form.authorizedTime &&
          CU.validateNumber(val, 0, undefined, 0)
        ) {
          this.form.qualityDeadline = moment(this.form.authorizedTime)
            .add(+val, "months")
            .toDate();
        }
      }
    },
    // 更改授权时间
    getAuthorizedTime(date) {
      this.form.authorizedTime = date;
      if (
        this.form.warrantyPeriod &&
        CU.validateNumber(this.form.warrantyPeriod, 0, undefined, 0)
      ) {
        this.form.qualityDeadline = moment(date)
          .add(+this.form.warrantyPeriod, "months")
          .toDate();
      }
    },
    getfarm() {
      this.$post(this.$api.BASE_MANAGE.LIST, {
        pageNO: 1,
        pageSize: 99,
      }).then((res) => {
        this.farmList = res.list;
        console.log(res)
      });
    },
    dealMapData(mapData) {
      this.form.address = mapData.address;
      this.form.longAndlat = mapData.lnglat;
      this.form.regionCode = mapData.addressComponent.adcode;
      this.form.thirdLongLat = mapData.thirdLongLat;
    },
    mapModelShow() {
      if (!this.form.farmId) {
        this.$Message.info("请先选择基地");
        return;
      }
      let farm = this.farmList.find((item) => item.id == this.form.farmId);
      this.backgroundPosition = farm ? farm.mapPosition : null;
      this.currentData = this.form.thirdLongLat;
      this.mapModel = true;
    },

    submit() {
      this.$refs.form.validate().then((rs) => {
        if (!rs) return;
        this.loading = true;
        let params = { ...this.form };
        params.authorizedTime = moment(params.authorizedTime).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        params.qualityDeadline = moment(params.qualityDeadline).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        params.isOnline = 0;
        this.$post(
          params.guid
            ? this.$api.DEVICE_LL.AUTHORIZATION_EDIT
            : this.$api.DEVICE_LL.AUTHORIZATION_ADD,
          params
        )
          .then(() => {
            this.$emit("submit-success");
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>